<template>
  <div
    class="card mb-4"
    v-if="this.$store.state.getMovie.status.personShowLoading"
  >
    <div class="card-header bg-success">
      <h4 class="text-center text-white my-2" v-text="person.name"></h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-lg-2">
          <img
            style="width: 300px"
            class="img-fluid rounded-1"
            v-if="person.profile_path"
            :src="imageSizeMovie('w185') + person.profile_path"
            :title="person.name"
            :alt="person.name"
          />
          <img
            style="width: 300px"
            class="img-fluid rounded-1"
            v-else
            src="../../assets/no_image.jpg"
            :title="person.name"
            :alt="person.name"
          />
          <h1 class="my-2 text-center" v-text="person.name"></h1>
        </div>
        <div class="col-md-9 col-lg-10">
          <p class="mb-1">
            <strong class="me-1" v-if="person.birthday">تاریخ تولد:</strong
            ><span dir="ltr" v-text="person.birthday"></span>
          </p>
          <p class="mb-1">
            <strong class="me-1" v-if="person.place_of_birth">محل تولد:</strong
            ><span dir="ltr" v-text="person.place_of_birth"></span>
          </p>
          <p class="mb-1">
            <strong class="me-1" v-if="person.deathday">تاریخ وفات:</strong
            ><span dir="ltr" v-text="person.deathday"></span>
          </p>
          <p class="mb-1">
            <strong class="me-1" v-if="person.biography">بیوگرافی:</strong
            ><span dir="ltr" v-text="person.biography"></span>
          </p>
          <p class="mb-1">
            <strong class="me-1" v-if="person.known_for_department"
              >بازیگر بخش:</strong
            ><span dir="ltr" v-text="person.known_for_department"></span>
          </p>
          <p class="mb-1">
            <strong class="me-1" v-if="person.popularity">امتیاز:</strong
            >{{ person.popularity }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <spinner />
  </div>
</template>

<script>
import Spinner from "@/components/template/spinner";
export default {
  name: "BioPerson",
  components: { Spinner },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
